<template>
  <div>
    <ActivitiesForDisableComp v-bind:mobile="mobile"/>
    <FollowUs v-bind:mobile="mobile"/>
  </div>
</template>

<script>
import FollowUs from '@/components/desktop/FollowUs'
import ActivitiesForDisableComp from '@/components/desktop/Activities/ActivitiesForDisableComp'

  export default {
    name: 'ActivitiesForDisable',
    props: ['mobile'],
    components: {
      FollowUs,
      ActivitiesForDisableComp
    },
  }
</script>
