<template>
  <div>
    <v-row justify="center" no-gutters>
      <h1 v-bind:class="mobile ? 'mainTitleMobile' : 'mainTitle mainTitleActivities'" v-html="$t(mainTitle)"></h1>
    </v-row>
    <v-row justify="center" align="center" class="mainContent" no-gutters>
      <v-col cols="1"></v-col>
      <v-col v-bind:cols="mobile ? 6 : 5" class="leftSection">
        <p v-bind:class="mobile ? 'textActivitiesMobile' : 'textActivities'" v-html="$t(text)"></p>
        <v-row justify="center" class="linksRight" v-if="!mobile">
          <v-col cols="5"
                 v-for="(link, index) in links"
                 :key="index"
          >
            <v-img
                style="cursor: pointer"
                :src="link.image"
                contain
                @click="routing(link.name)"
                height="150"
            />
            <h3 class="linksTitle"
                @click="routing(link.name)"
                v-html="$t(link.title)" ></h3>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-bind:cols="mobile ? 4 : 5">
        <v-img
            :src="require('@/assets/picto/pictoPeople4.svg')"
            contain
            v-bind:height="mobile ? 300 : 500"
        />
      </v-col>
      <v-col cols="1"></v-col>
      <v-row justify="space-between" no-gutters v-if="mobile">
        <v-col cols="1"></v-col>
        <v-col cols="5"
               v-for="(link, index) in links"
               :key="index"
        >
          <v-img
              style="cursor: pointer"
              :src="link.image"
              contain
              @click="routing(link.name)"
              height="125"
          />
          <h3 class="linksTitleMobile"
              @click="routing(link.name)"
              v-html="$t(link.title)" ></h3>
        </v-col>
        <v-col cols="1"></v-col>
      </v-row>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'ActivitiesForDisableComp',
  props: ['mobile'],
  data: () => ({
    mainTitle: "",
    text: "",
    currentSection: '',
    links: [
      {
        title: "mainTitlePhysical",
        name: "Activities1",
        image: require('@/assets/vitaeImg/section23.png')
      },
      {
        title: "mainTitleOlympics",
        name: "Activities3",
        image: require('@/assets/vitaeImg/section17.png')
      },
      {
        title: "mainTitlePhysicalAway",
        name: "Activities2",
        image: require('@/assets/vitaeImg/section20.png')
      }
    ]
  }),
  methods: {
    routing(nameRoute) {
      this.$vuetify.goTo(0)
      if (this.$route.name !== nameRoute) {
        this.$router.push({name: nameRoute}).then(() => {
          this.links.push(this.currentSection[0])
          this.changeRoute(nameRoute)
          this.$vuetify.goTo(0)
        })
      }
    },
    changeRoute (name) {
      let indexForCurrentRoute = 'mainTitlePhysical'
      switch (name) {
        case 'Activities1':
          this.$set(this, 'mainTitle', 'mainTitlePhysical')
          this.$set(this, 'text', 'textPhysical')
          indexForCurrentRoute = this.links.findIndex(link => link.title === 'mainTitlePhysical')
          break;
        case 'Activities2':
          this.$set(this, 'mainTitle', 'mainTitlePhysicalAway')
          this.$set(this, 'text', 'textPhysical')
          indexForCurrentRoute = this.links.findIndex(link => link.title === 'mainTitlePhysicalAway')
          break;
        case 'Activities3':
          this.$set(this, 'mainTitle', 'mainTitleOlympics')
          this.$set(this, 'text', 'textPhysical')
          indexForCurrentRoute = this.links.findIndex(link => link.title === 'mainTitleOlympics')
          break;
        default:
          this.$set(this, 'mainTitle', 'mainTitlePhysical')
          this.$set(this, 'text', 'textPhysical')
          indexForCurrentRoute = this.links.findIndex(link => link.title === 'mainTitlePhysical')
      }
      let splicedSection = this.links.splice(indexForCurrentRoute, 1);
      this.$set(this, 'currentSection', splicedSection)
    }
  },
  created() {
    // remove from array current route
    let currentRoute = this.$route.name
    this.changeRoute(currentRoute)
  }
}
</script>

<i18n>
{
  "en": {
    "mainTitlePhysical": "Adapted physical activities offered: <br> Establishment welcoming disabled people",
    "textPhysical": "We offer many adapted physical activities in institutes that adapt to all fitness levels and all tastes: <strong> gentle gym, yoga, Pilate, aquagym, velaqua, fall prevention, active walking, Nordic walking. </strong>",
    "mainTitlePhysicalAway": "My physical activities at a distance",
    "textPhysicalAway": "Mon activités physiques à distances",
    "mainTitleOlympics": "Olympics"
  },
  "fr": {
    "mainTitlePhysical": "Les activités physiques adaptées proposées : <br> Etablissement accueillant des publics handicapés",
    "textPhysical": "Nous proposons de nombreuses activités physique adaptée en institut qui s’adapte à tous les niveaux de forme et à toutes les envies : <strong> gym douce, yoga, Pilate, aquagym, velaqua, prévention de la chute, marche active, marche nordique. </strong>",
    "mainTitlePhysicalAway": "Mon activité physique à distance",
    "textPhysicalAway": "Mon activités physiques à distances",
    "mainTitleOlympics": "Les olympiades"
  }
}
</i18n>

<style scoped>

.leftSection {
  padding-left: 200px;
  padding-right: 100px;
}

.mainContent {
  padding-bottom: 100px;
}

.linksRight {
  padding-top: 50px;
}

.linksTitle {
  cursor: pointer;
  font-family: Comfortaa, sans-serif;
  text-align: center;
  color: #57939E;
  padding-top: 25px;
}

.linksTitleMobile {
  font-family: Comfortaa, sans-serif;
  text-align: center;
  color: #57939E;
  padding-top: 25px;
  font-size: 14px;
}

@media screen and (max-width: 1500px) {
  .mainTitleActivities {
    text-align: center;
    padding-right: 200px;
    padding-left: 200px;
  }
}
@media screen and (min-width: 1500px) {
  .mainTitleActivities {
    text-align: center;
    padding-right: 400px;
    padding-left: 400px;
  }
}

.textActivitiesMobile {
  color: #808080;
  font-size: 14px;
}

.textActivities {
  color: #808080;
}

</style>
